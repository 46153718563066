import React, {
  FC,
} from 'react';
import { useNavigate } from 'react-router';

import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { ContentFile } from '../../../../Content/modules/ContentFile/types.contentFile';
import {
  getCreatePhotosPath,
  getPhotosPath,
} from '../../../../Content/modules/Photo/navigation.photo';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';

import PropertyBlueprintsImagesSection from '../../../../PropertyProgress/pages/PropertyBlueprints/components/PropertyBlueprintsImagesSection';
import {
  PropertyPhotosSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../../PropertyProgress/types.propertyProgress';

const SellingPropertyInfoPhotosSection: FC<PropertyProgressCategorySectionProps<PropertyPhotosSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { photos = [] } = section.data;
  const blobs = photos.flatMap((photo) => photo.blobs);

  const handleViewImages = () => navigate(getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }));

  const handleContentFilesSelected = (files: Array<ContentFile>) =>
    navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId, contentFileIds: files.map((file) => file.id) }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyBlueprintsImagesSection
          blobs={blobs}
          onContentFilesSelected={handleContentFilesSelected}
          onViewImages={handleViewImages}
        />
      }
      key={section.id}
      section={section}
    />
  );
};

export default SellingPropertyInfoPhotosSection;
