import React from 'react';
import {
  Divider,
  Stack,
  useTheme,
} from '@mui/material';

import DeclarationCalculationsTable, { DeclarationCalculationsTableItem } from './DeclarationCalculationsTable';

type DeclarationGroupsProps = {
  groups: DeclarationCalculationsTableItem[][];
  loading?: boolean;
};

const DeclarationCalculationsGroups: React.FC<DeclarationGroupsProps> = ({
  groups,
  loading,
}) => {
  const theme = useTheme();

  return (
    <Stack sx={{ gap: theme.spacing(1.5) }}>
      {groups.map((items, index) => (
        <>
          <DeclarationCalculationsTable
            items={items}
            key={index}
            loading={loading}
          />
          {(groups.length !== (index + 1)) && (<Divider />)}
        </>
      ))}
    </Stack>
  );
};
export default DeclarationCalculationsGroups;
