import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AnalyticsEvent,
  EventReportSource,
} from '@house-id/houseid-types/dist/analytics';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

import {
  NeutralSmile,
  Discount,
  Wallet,
} from '../../../../../components/icons/Icons';
import OrdnaImage from '../../../../../assets/images/ordna.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import HIDLink from '../../../../../components/HIDLink';
import { FCC } from '../../../../../types/common';
import { useGetLoansSummaryQuery } from '../../../modules/Finances/api/api.finance';
import { formatPercentage } from '../../../../../utils/number';
import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../../../utils/string';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { ORDNA_READ_MORE_URL } from '../../../../../constants/links';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { openUrlNewTab } from '../../../../../utils/file';
import useGetLogAnalyticsEvent from '../../../../Analytics/hooks/useGetLogAnalyticsEvent';

type InfoSquareProps = {
  Icon: React.ReactElement;
  label: string;
  value: string;
};

const InfoSquare: FCC<InfoSquareProps> = ({
  Icon,
  label,
  value,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        padding: 1.5,
        backgroundColor: theme.palette.common.white,
        ...sx,
      }}
    >
      <Stack sx={{ spacing: 1.5, marginBottom: 1.5 }}>
        {Icon}
      </Stack>
      <Typography sx={{ marginBottom: 0.5 }} variant="body1">{label}</Typography>
      <Typography variant="h4">{value}</Typography>
    </Stack>
  );
};

export const useGetInterestRateWidgetVisible = () => {
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: loansSummary, isLoading: loansIsLoading } = useGetLoansSummaryQuery(propertyId ? { propertyId } : skipToken);

  return !loansIsLoading && Boolean(loansSummary?.ordna?.proposal);
};

const InterestRateWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const { isDownMd, isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: loansSummary, isLoading: loansIsLoading } = useGetLoansSummaryQuery(propertyId ? { propertyId } : skipToken);

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const handleChallengeInterestRage = () => openMobileAppDialog({
    unavailableFeature: EntityType.LOAN,
  });

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const handleReadMore = () => {
    openUrlNewTab(ORDNA_READ_MORE_URL);
    logAnalyticsEvent(
      {
        event: AnalyticsEvent.ORDNA_READ_MORE,
        source: PartnersSource.ORDNA,
        hidCategory: EntityType.LOAN,
        eventReportSource: EventReportSource.ALL,
      },
    );
  };

  if (loansIsLoading || !loansSummary?.ordna?.proposal) {
    return null;
  }

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.lightest,
        padding: theme.spacing(2.5),
      }}
    >
      <Stack>
        <Stack direction={isDownMd ? 'column' : 'row'} spacing={2.5}>
          <Stack>
            <Typography sx={{ marginBottom: 1 }} variant="h5">
              {t('home:home_lower_your_mortgage_interest_rate')}
            </Typography>
            <Typography sx={{ marginBottom: 1.5 }} variant="body1">
              {t('home:home_let_ordna_challenge_your_interest_rate')}
            </Typography>
            <HIDButton sx={{ alignSelf: 'flex-start' }} onClick={handleChallengeInterestRage}>
              {t('home:home_challenge_the_interest_rate')}
            </HIDButton>
          </Stack>
          <Stack
            direction={isDownSm ? 'column' : 'row'}
            justifyContent="stretch"
            spacing={1.5}
            sx={{ flex: 1 }}
          >
            <InfoSquare
              Icon={<NeutralSmile iconColor={theme.palette.warning.light} />}
              label={t('home:home_my_average_interest_rate_from_loans', { count: loansSummary.numberOfLoans || 1 })}
              sx={{ flex: 0.5 }}
              value={
                loansSummary.weightedAverageInterestRate !== undefined
                  ? formatPercentage(loansSummary.weightedAverageInterestRate)
                  : EMPTY_VALUE
              }
            />
            <Stack
              direction="row"
              justifyContent="stretch"
              spacing={1.5}
              sx={{ flex: 1 }}
            >
              <InfoSquare
                Icon={<Discount iconColor={theme.palette.primary.light} />}
                label={t('home:home_order_average_12_months')}
                sx={{ flex: 1 }}
                value={
                  loansSummary.ordna.averageInterestRates
                    ? formatPercentage(loansSummary.ordna.averageInterestRates.lastTwelveMonthAverage)
                    : EMPTY_VALUE
                }
              />
              <InfoSquare
                Icon={<Wallet iconColor={theme.palette.common.black} />}
                label={t('home:home_savings_per_month')}
                sx={{ flex: 1 }}
                value={formatMoney(loansSummary.ordna.proposal.monthlySaving)}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction={isDownSm ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        spacing={1.5}
        sx={{ marginTop: 2 }}
      >
        <Typography
          sx={{ color: theme.palette.grey[500] }}
          variant="body2"
        >
          {t('home:home_the_proposal_is_an_estimate')}
        </Typography>
        <Stack alignItems={isDownSm ? 'center' : 'flex-end'} direction="row" spacing={1.5}>
          <HIDLink
            label={t('home:home_read_more_about_ordna')}
            sx={{
              color: theme.palette.grey[500],
              minWidth: 120,
            }}
            variant="body2"
            onClick={handleReadMore}
          />
          <img src={OrdnaImage} style={{ width: 78, height: 18 }} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default InterestRateWidget;
