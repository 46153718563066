import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import FinanceOverviewWidget from './FinanceOverviewWidget';
import { FCC } from '../../../../../../../types/common';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../api/api.propertyFinance';
import HIDSection from '../../../../../../../components/HIDSection';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import DocMoney from '../../../../../../../components/icons/DocMoney';
import { getDeclarationCalculationPath } from '../../../modules/Declaration/navigation.declaration';
import useGetCurrentProperty from '../../../../../hooks/useCurrentProperty';
import DeclarationInfo from '../../../modules/Declaration/components/DeclarationInfo';
import { BorderRadius } from '../../../../../../../constants/borderRadius';
import DeclarationCalculationsIncomeStatement
  from '../../../modules/Declaration/pages/DeclarationCalculations/components/DeclarationCalculationsIncomeStatement';

const DeclarationOverviewWidget: FCC = ({
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'forms_common', 'finances', 'declarations']);
  const theme = useTheme();
  const { data: { id: propertyId } = {} } = useGetCurrentProperty();

  const { isDownLg, isDownSm } = useBreakPointsSizes();

  const {
    data: financeSaleSummary,
    isLoading,
    isFetching,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const balance = financeSaleSummary?.resultSummary?.balance || 0;

  const handleNavigateValuation = () => navigate(getPathWithPropertyIdOrInit(getDeclarationCalculationPath, { propertyId }));

  const Container = isDownSm ? Stack : HIDSection;

  return balance || isLoading || isFetching
    ? (
      <Container sx={{ gap: 2, ...sx }}>
        <DeclarationInfo
          showIcon={!isDownLg}
        />
        <Stack
          flexDirection={isDownLg ? 'row' : 'column'}
          gap={isDownLg ? 0 : 1}
          justifyContent={isDownLg ? 'space-between' : 'flex-end'}
        >
          <HIDSection sx={{
            backgroundColor: theme.palette.grey[100],
            gap: 1,
            borderStyle: 'unset',
            width: '100%',
            borderRadius: `${BorderRadius.md}px`,
          }}
          >
            <DeclarationCalculationsIncomeStatement hideActions />
          </HIDSection>

        </Stack>
        <HIDButton
          sx={{ alignSelf: 'flex-end', minWidth: 120 }}
          onClick={handleNavigateValuation}
        >
          {t('common:view')}
        </HIDButton>
      </Container>
    )
    : (
      <FinanceOverviewWidget
        Icon={DocMoney}
        action={t('common:get_started')}
        description={t('finances:make_sales_calculation_description')}
        iconBackgroundColor={theme.palette.warning.lighter}
        iconColor={theme.palette.warning.dark}
        sx={sx}
        title={t('finances:make_sales_calculation')}
        widgetBackground={theme.palette.primary.lightest}
        onClick={handleNavigateValuation}
      />
    );
};

export default DeclarationOverviewWidget;
