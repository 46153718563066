export enum FinancePageNames {
  BUDGET_CATEGORIES = 'budgetCategories',
  RECURRING_EXPENSES = 'recurringExpenses',
  VALUATION = 'valuation',
  DECLARATION = 'declaration',
}

export enum FinancesFilterSectionMode {
  Filter = 'Filter',
  Compare = 'Compare',
}
