import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { EntityContentConnectionsMap } from '../../types/types.content';

export enum TaskExpirationTypes {
  DATE = 'date',
  TIME = 'time',
}

export type RelatesToItemType = EntityType;

export type RelatesToItem = {
  id: string,
  name: string,
  type: RelatesToItemType,
};

export enum TaskType {
  CUSTOM = 'custom',
  TEMPLATE = 'template',
  PARTNER = 'partner',
}

export type RRuleJSON = {
  freq: number,
  interval: number,
  byweekday?: Array<number>,
  bymonth?: Array<number>,
  bymonthday?: Array<number>,
};

export type TaskTemplateCategory = {
  code: string,
  name: string,
  color: string,
};

export type TaskTemplate = {
  id: string,
  name: string,
  description?: string,
  consequence?: string,
  category: TaskTemplateCategory,
  suggestedEstimatedCost?: {
    amount: number,
    currency: string,
  },
  suggestedExpiration?: {
    type: TaskExpirationTypes,
    date: string,
  },
  suggestedRecurringScheduleConfiguration?: {
    rrule: RRuleJSON,
    templateId: string,
  },
  youtubeId?: string,
  descriptionArticleId?: string,
  consequenceArticleId?: string,
};

export type Task = {
  id: string,
  propertyId: string,
  type: TaskType,
  templateId?: string,
  name: string,
  relatesTo: Array<RelatesToItem>,
  classifications: Array<string>,
  duration?: {
    actual?: number
  },
  cost: {
    estimated?: number,
    actual?: number,
    currencyCode: string,
  },
  order: number,
  color?: string,
  createdAt: string,
  updatedAt: string,
  completedAt?: string,
  description?: string,
  consequence?: string,
  noteAndConsequence?: string,
  recurringScheduleConfiguration?: {
    rrule?: RRuleJSON,
    templateId: string,
  },
  reminderConfiguration?: {
    enabled: boolean,
  },
  expiration: {
    type: TaskExpirationTypes,
    date: string,
    label?: string,
  } | null,
  previousTasks?: Array<{ id: string, completedAt: string }>,
  groupIds?: Array<string>,
  youtubeId?: string,
  descriptionArticleId?: string,
  consequenceArticleId?: string,
  tags?: {
    propertyCheckSeverity: Array<any>,
  },
  additionalInfo?: Array<{ label: string, value: string }>,
  _meta: {
    partial: boolean
  },
  connections?: EntityContentConnectionsMap,
};
