import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { ContentFile } from '../../../../Content/modules/ContentFile/types.contentFile';
import {
  getCreatePhotosPath,
  getPhotosPath,
} from '../../../../Content/modules/Photo/navigation.photo';
import PropertyProgressCategorySectionContainer from '../../../../PropertyProgress/components/PropertyProgressCategorySectionContainer';

import PropertyBlueprintsImagesSection from '../../../../PropertyProgress/pages/PropertyBlueprints/components/PropertyBlueprintsImagesSection';
import {
  PropertyPhotosSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../../PropertyProgress/types.propertyProgress';

const SellingPropertyArchivePhotos: FC<PropertyProgressCategorySectionProps<PropertyPhotosSectionData>> = ({
  section,
}) => {
  const { t } = useTranslation(['property', 'selling_property']);
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();

  const { photos = [] } = section.data;
  const blobs = photos.flatMap((photo) => photo.blobs);

  const handleAddImage = () => navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId }));
  const handleViewImages = () => navigate(getPathWithPropertyIdOrInit(getPhotosPath, { propertyId }));

  const handleContentFilesSelected = (files: Array<ContentFile>) =>
    navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId, contentFileIds: files.map((file) => file.id) }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyBlueprintsImagesSection
          blobs={blobs}
          buttonText={t('property:property_progress_property_blueprints_additional_images_add')}
          description={t('selling_property:selling_property_archive_section_photos_description')}
          onAddImage={handleAddImage}
          onContentFilesSelected={handleContentFilesSelected}
          onViewImages={handleViewImages}
        />
      }
      key={section.id}
      section={section}
    />
  );
};

export default SellingPropertyArchivePhotos;
