import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import DeclarationCalculationsSection from './DeclarationCalculationsSection';
import {
  formatMoney,
} from '../../../../../../../../../utils/string';
import { FCC } from '../../../../../../../../../types/common';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import DeclarationCalculationsGroups from './DeclarationCalculationsGroups';
import useGetDeductionCalculationSections, { DeductionCalculationSectionType } from '../../../hooks/useGetDeductionCalculationSections';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../../../api/api.propertyFinance';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { SaleTaxableResult } from '@house-id/houseid-types/dist/finances/finances';

const DeclarationCalculationsSalesNumber: FCC = ({ sx }) => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations', 'finances']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: saleSummary } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: groups = [],
    isLoading: isDeductionCalculationSectionsLoading,
    isFetching: isDeductionCalculationSectionsFetching,
  } = useGetDeductionCalculationSections({ section: DeductionCalculationSectionType.SALES_NUMBER });

  const loading = isDeductionCalculationSectionsLoading || isDeductionCalculationSectionsFetching;

  const [openManagePurchasePriceModal] = useDialog(DialogNames.MANAGE_PROPERTY_SALES_NUMBERS_DIALOG);
  const handleManageSalesFigures = () => openManagePurchasePriceModal();

  return (
    <DeclarationCalculationsSection
      sx={sx}
      title={t('declarations:declarations_declaration_sales_number_title')}
    >
      <DeclarationCalculationsGroups groups={groups} loading={loading} />
      {saleSummary?.type === SaleTaxableResult.PROFIT && (
        <Stack
          alignItems="center"
          sx={{
            gap: theme.spacing(1),
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: BorderRadius.md,
          }}
        >
          <HIDTypography variant="h5">{formatMoney(saleSummary?.taxCalculation?.taxReductionDueToDeductions || 0)}</HIDTypography>
          <HIDTypography sx={{ textAlign: 'center' }}>{t('declarations:declarations_sales_number_description')}</HIDTypography>
        </Stack>
      )}
      <HIDButton
        color="secondary"
        size="medium"
        sx={{ alignSelf: 'flex-end', marginTop: theme.spacing(2) }}
        onClick={handleManageSalesFigures}
      >
        {t('declarations:declarations_manage_numbers')}
      </HIDButton>
    </DeclarationCalculationsSection>
  );
};

export default DeclarationCalculationsSalesNumber;
