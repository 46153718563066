export const ALL_DOCUMENTS_CATEGORY = 'all';

export const DEFAULT_CLASSIFICATION = 'miscellaneous';

export const WARRANTY_CLASSIFICATION = 'warranty';
export const PROTOCOLS_CLASSIFICATION = 'protocol';
export const MAINTENANCE_CLASSIFICATION = 'care_instruction';
export const MANUAL_CLASSIFICATION = 'manual';
export const DRAWINGS_CLASSIFICATION = 'blueprint';
export const SPECIFICATIONS_CLASSIFICATION = 'specification';

export const DRAWINGS_CATEGORY = 'blueprint';

export const CERTIFICATE_CATEGORY = 'certificate';
export const CERTIFICATE_CATEGORY_TITLE_DEED_TYPE = 'houseid.se/document/type/certificate/title-deed';

export const CONTRACT_CATEGORY = 'contract';
export const CONTRACT_CATEGORY_MORTGAGE_DEED_TYPE = 'houseid.se/document/type/contract/mortgage-deed';
