import { useTranslation } from 'react-i18next';

import FinancesImage from '../../../../../assets/images/finance.png';

export enum TutorialDialogTypes {
  RECURRING_EXPENSE = 'RECURRING_EXPENSE',
  BUDGET = 'BUDGET',
  VALUATION = 'VALUATION',
  DECLARATION = 'DECLARATION',
}

export type TutorialStep = {
  title: string;
  description: string;
  image: string;
};

const useGetTutorialDialogSections = (type: TutorialDialogTypes) => {
  const { t } = useTranslation(['finances']);

  const TutorialDialogSections: Record<TutorialDialogTypes, Array<TutorialStep>> = {
    [TutorialDialogTypes.RECURRING_EXPENSE]: [
      {
        title: t('finances:fixed_expenses'),
        description: t('finances:recurring_expenses_step_1_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:budgets'),
        description: t('finances:recurring_expenses_step_2_description'),
        image: FinancesImage,
      },
    ],
    [TutorialDialogTypes.BUDGET]: [
      {
        title: t('finances:budgets'),
        description: t('finances:budget_step_1_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:fixed_expenses'),
        description: t('finances:budget_step_2_description'),
        image: FinancesImage,
      },
    ],
    [TutorialDialogTypes.VALUATION]: [
      {
        title: t('finances:valuation_step_1_title'),
        description: t('finances:valuation_step_1_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:valuation_step_2_title'),
        description: t('finances:valuation_step_2_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:valuation_step_3_title'),
        description: t('finances:valuation_step_3_description'),
        image: FinancesImage,
      },
    ],
    [TutorialDialogTypes.DECLARATION]: [
      {
        title: t('finances:declaration_tutorial_step_1_title'),
        description: t('finances:declaration_tutorial_step_1_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:declaration_tutorial_step_2_title'),
        description: t('finances:declaration_tutorial_step_2_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:declaration_tutorial_step_3_title'),
        description: t('finances:declaration_tutorial_step_3_description'),
        image: FinancesImage,
      },
      {
        title: t('finances:declaration_tutorial_step_4_title'),
        description: t('finances:declaration_tutorial_step_4_description'),
        image: FinancesImage,
      },

    ],
  };

  return TutorialDialogSections[type];
};

export default useGetTutorialDialogSections;
