import qs from 'query-string';
import {
  LoansSummary,
} from '@house-id/houseid-types/dist/finances/finances';

import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';
import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { InsuranceWithParams } from '../../Content/modules/Insurance/types.insurance';

export const financeApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoansSummary: builder.query<LoansSummary, PropertyId>({
      query: ({ propertyId }) => `/loans?${qs.stringify({ propertyId })}`,
      providesTags: () => [HIDApiTags.LOAN],
    }),
    getInsurances: builder.query<Array<InsuranceWithParams>, void>({
      query: () => 'v2/insurances',
      transformResponse: (response: { insurances: Array<InsuranceWithParams> }) => response.insurances,
      providesTags: () => [HIDApiTags.INSURANCES],
    }),
  }),
});

export const {
  useGetLoansSummaryQuery,
  useGetInsurancesQuery,
} = financeApi;
