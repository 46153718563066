import React, {
  FC,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import NoInsuranceBackground from '../../../../../assets/images/finances_loans.png';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import { ChartIcon } from '../../../../../components/icons/Icons';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { usePatchPropertyFinanceInformationMutation } from '../api/api.propertyFinance';

type NoLoansCardProps = {
  hasNoLoans?: boolean;
  description?: string;
};

const NoLoansCard: FC<NoLoansCardProps> = ({ hasNoLoans: hasNoLoansProp, description }) => {
  const [hasNoLoans, setHasNoLoans] = useState(hasNoLoansProp);

  const theme = useTheme();
  const { t } = useTranslation(['finances']);
  const imageUrl = NoInsuranceBackground;

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    isDownXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  } = useBreakPointsSizes();

  const headerHeight = R.cond([
    [() => isDownXs, R.always(160)],
    [() => isDownSm, R.always(160)],
    [() => isDownMd, R.always(320)],
    [() => isDownLg, R.always(400)],
    [() => isDownXl, R.always(400)],
    [R.T, R.always(320)],
  ])();

  const logoHeight = R.cond([
    [() => isDownXs, R.always(48)],
    [() => isDownSm, R.always(64)],
    [() => isDownMd, R.always(80)],
    [() => isDownLg, R.always(80)],
    [() => isDownXl, R.always(80)],
    [R.T, R.always(80)],
  ])();

  const [patchPropertyFinanceInformation] = usePatchPropertyFinanceInformationMutation();

  const handleNoLoansUpdate = (value: boolean) => {
    setHasNoLoans(value);

    if (propertyId) {
      patchPropertyFinanceInformation({ propertyId, financeInformation: { hasNoLoans: value } });
    }
  };

  return (
    <Stack spacing={2}>
      <Box sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: headerHeight,
      }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          padding={2}
          spacing={2}
          sx={{ height: headerHeight }}
        >
          <Card sx={{
            borderRadius: logoHeight / 2,
          }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: logoHeight,
                width: logoHeight,
              }}
            >
              <ChartIcon size={36} sx={{ flex: 1 }} />
            </Stack>
          </Card>
          <Typography color={theme.palette.common.white} textAlign="center" typography={{ sm: 'h3', xxs: 'h5' }}>
            {t('finances:no_loans_card_title')}
          </Typography>
        </Stack>
      </Box>
      <Typography>
        {description || t('finances:no_loans_card_description')}
      </Typography>
      <FormControlLabel
        checked={hasNoLoans}
        control={<Checkbox />}
        label={t('finances:no_loans_card_has_no_loans')}
        sx={{ width: 'fit-content' }}
        onChange={(_, value) => handleNoLoansUpdate(value)}
      />
    </Stack>
  );
};

export default NoLoansCard;
