import React, { FC } from 'react';
import {
  Divider,
  Grid,
  SxProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';

import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import { useNavigateBackOr } from '../../../../../../utils/routes';
// import Loan from '../../../../../../components/icons/Loan';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import HIDTypography from '../../../../../../components/HIDTypography';
// import FinanceOverviewWidget from './components/FinanceOverviewWidget';
import RecurringExpensesOverview from './components/RecurringExpensesOverview';
import BudgetsOverviewWidget from './components/BudgetsOverviewWidget';
import ValuationOverviewWidget from './components/ValuationOverviewWidget';
import { hidSpacing } from '../../../../../../utils/number';
import { getActiveAuth } from '../../../../../../external-services/firebase';
import { useGetTokenDataQuery } from '../../../../../Auth/api/api.user';
import { isTestEnv } from '../../../../../../utils/env';
import DeclarationOverviewWidget from './components/DeclarationOverviewWidget';

enum FinancesOverviewWidget {
  Expense = 'expense',
  Budget = 'budget',
  Valuation = 'valuation',
  Declaration = 'declaration',
}

const FinancesOverview: FC = () => {
  const { t } = useTranslation(['finances', 'home']);

  const { isDownSm } = useBreakPointsSizes();

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const showValuation = isTestEnv || tokenData?.isAdmin;

  const widgets = [{
    id: FinancesOverviewWidget.Expense,
    Component: (params: { sx: SxProps }) => <RecurringExpensesOverview showActions showIcons {...params} />,
  },
  {
    id: FinancesOverviewWidget.Budget,
    Component: (params: { sx: SxProps }) => <BudgetsOverviewWidget showActions showIcons {...params} />,
  },
  showValuation && {
    id: FinancesOverviewWidget.Valuation,
    Component: (params: { sx: SxProps }) => <ValuationOverviewWidget {...params} />,
  },
  showValuation && {
    id: FinancesOverviewWidget.Declaration,
    Component: (params: { sx: SxProps }) => <DeclarationOverviewWidget {...params} />,
  },
  ].filter(Boolean);

  const MobileDivider = (
    <Divider
      sx={{
        width: `calc(100% + ${hidSpacing(2)}px)`,
        marginTop: 2,
      }}
    />
  );

  return (
    <HomeLayout
      title={t('finances:finances')}
      onBack={handleGoBack}
    >
      <HIDTypography sx={{ mb: isDownSm ? 2 : 4 }}>
        {t('finances:get_complete_control_of_your_accommodation_finances')}
      </HIDTypography>
      {propertyId !== undefined && (
        <Grid
          container
          direction="row"
          spacing={2}
        >
          {widgets.map(({ id, Component }) => (
            <React.Fragment key={id}>
              {isDownSm && MobileDivider}
              <Grid
                item
                alignItems="stretch"
                md={6}
                xxs={12}
              >
                <Component sx={{ height: '100%' }} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </HomeLayout>
  );
};

export default FinancesOverview;
