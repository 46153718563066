import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  GridAlignment,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useDebounce } from 'usehooks-ts';
import { RecurringExpense } from '@house-id/houseid-types/dist/finances/recurringExpenses';
import { ChartDataItem } from '@house-id/houseid-types/dist/finances/finances';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../../navigation/navigation.property';
import {
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../../../../../utils/routes';
import RecurringExpensesActions from '../components/RecurringExpensesActions';
import FiltersSection from '../../../../Content/components/sections/FiltersSection';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDLink from '../../../../../../../components/HIDLink';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDCircularProgress from '../../../../../../../components/progress/HIDCircularProgress';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import useGetEnum from '../../../../../../../hooks/useGetEnum';
import { EnumType } from '../../../../../../../types/common';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { roundPercentage } from '../../../../../../../utils/number';
import { formatMoney } from '../../../../../../../utils/string';
import HomeListLayout from '../../../../../pages/Home/components/HomeListLayout';
import { getRecurringExpenseCategoryPath } from '../navigation.recurringExpenses';
import {
  useGetRecurringExpensesBankAccountsQuery,
  useGetRecurringExpensesFiltrationQuery,
} from '../api/api.recurringExpenses';
import FinancesQuickNavigation from '../../../components/FinancesQuickNavigation';
import {
  FinancePageNames,
  FinancesFilterSectionMode,
} from '../../../types.finances';
import { ALL_RECURRING_EXPENSES_CATEGORY } from '../../../constants.finance';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { getFinancesPath } from '../../../navigation.finances';
import ExpensesComparisonChart from '../components/ExpensesComparisonChart';
import RecurringExpensesFilterSection, { RecurringExpensesFilters } from '../components/RecurringExpensesFilterSection';
import { TimeIntervalsGroupingType } from '../../../../Content/types/types.content';
import { FILTER_DEBOUNCE_TIME } from '../../../../../../../constants/layout';
import RecurringExpenseSuggestionsStatusBox from '../components/RecurringExpenseSuggestionsStatusBox';
import {
  getFromToFullMonthDateRange,
  getLastSyncedFromBankAccounts,
} from '../utils.recurringExpenses';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { TutorialDialogTypes } from '../../../../../components/dialogs/tutorialDialog/useGetTutorialDialogSections';
import RecurrentExpensesEmptyStateCard from '../components/RecurrentExpensesEmptyStateCard';
import useShowDialogs from '../../../../../../../hooks/useShowDialogs';
import {
  ShowDialogItemTargetType,
  ShowDialogItem,
} from '../../../../../../../types/dialogs';

type GroupedRecurringExpensesCategory = {
  id: string;
  totalAmount: number;
  count: number;
};

type PercentageLabelColumnType = {
  percentage: number;
  label: string;
};

const groupRecurringExpenses = (recurringExpenses: Array<RecurringExpense>): Array<GroupedRecurringExpensesCategory> => {
  const categoriesList = R.pipe(
    R.groupBy((expense: RecurringExpense) => expense.category),
    R.toPairs,
    R.map(([categoryId, expenses = []]) => ({
      id: categoryId,
      totalAmount: R.sum(R.map((expense: RecurringExpense) => expense.totalAmount, expenses)),
      count: expenses.length,
    })),
    R.sort((a, b) => b.totalAmount - a.totalAmount),
  )(recurringExpenses);

  const totalAmount = R.sum(R.map(({ totalAmount }) => totalAmount, categoriesList));
  const count = R.sum(R.map(({ count }) => count, categoriesList));

  const allCategoryItem = {
    id: ALL_RECURRING_EXPENSES_CATEGORY,
    totalAmount,
    count,
  };

  return count > 0 ? [allCategoryItem, ...categoriesList] : [];
};

const getFromToIntervalFormatted = (chartData: Array<ChartDataItem>) => {
  if (chartData.length < 1) {
    return '';
  }

  const from = R.head(chartData) as ChartDataItem;
  const to = R.last(chartData) as ChartDataItem;

  return [from, to]
    .map((interval) => formatDate(new Date(interval.date.year, interval.date.month, 1), DateTimeFormats.MONTH_AND_YEAR))
    .join(' - ');
};

const now = new Date();

const RecurringExpensesCategories: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { t } = useTranslation(['finances', 'common']);
  const theme = useTheme();

  const { manageExpenses } = useRouteQueryParams<{ manageExpenses?: boolean }>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { isDownSm } = useBreakPointsSizes();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const { data: bankAccounts = [] } = useGetRecurringExpensesBankAccountsQuery();

  const { from: fromDateInitial } = getFromToFullMonthDateRange(12);

  const [filters, setFilters] = useState<RecurringExpensesFilters>({
    mode: FinancesFilterSectionMode.Filter,
    fromDate: fromDateInitial,
    toDate: undefined,
    fromYear: now.getFullYear(),
    toYear: undefined,
    timeIntervalGroupingType: TimeIntervalsGroupingType.Monthly,
    includeIncompleteMonths: true,
  });

  const lastSyncedAt = getLastSyncedFromBankAccounts(bankAccounts, filters.bankAccountIds);

  const debouncedFilters = useDebounce(
    filters,
    FILTER_DEBOUNCE_TIME,
  );

  const {
    data: filtration,
    isLoading,
    isFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    from: debouncedFilters.fromDate?.toISOString(),
    to: debouncedFilters.toDate?.toISOString(),
    accounts: debouncedFilters.bankAccountIds,
    grouping: debouncedFilters.timeIntervalGroupingType,
    includeIncompleteMonths: debouncedFilters.includeIncompleteMonths,
  });

  const isLoadingOrFetching = isLoading || isFetching;

  const hasExpenses = Boolean(filtration?.expenses?.length);

  const { data: ExpensePaymentCategoryEnum } = useGetEnum(EnumType.ExpensePaymentCategory);

  const intervalsLabel = getFromToIntervalFormatted(filtration?.chart || []);

  const costsLabel: string = t('finances:costs');
  const allCategoriesLabel = `${t('common:all_label')} ${costsLabel.toLowerCase()}`;

  const categoriesList = useMemo(
    () => groupRecurringExpenses(filtration?.expenses || []),
    [filtration?.expenses],
  );

  const totalAmount = categoriesList?.[0]?.totalAmount;

  const [openTutorialDialog] = useDialog(DialogNames.TUTORIAL_DIALOG);

  const columns: Array<GridColDef> = [
    {
      field: 'id',
      headerName: t('common:categories'),
      flex: 0.4,
      type: 'string',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        const groupedReceiptCategory = params?.row as GroupedRecurringExpensesCategory;

        return {
          percentage: groupedReceiptCategory?.totalAmount
            ? Math.round(roundPercentage(groupedReceiptCategory.totalAmount / (totalAmount || 1), 2) * 100)
            : 0,
          label: groupedReceiptCategory.id === ALL_RECURRING_EXPENSES_CATEGORY
            ? allCategoriesLabel
            : (ExpensePaymentCategoryEnum || {})[groupedReceiptCategory.id],
        } as PercentageLabelColumnType;
      },
      sortComparator: (a: PercentageLabelColumnType, b: PercentageLabelColumnType) => a.label?.localeCompare(b.label),
      renderCell: (params: GridRenderCellParams) => {
        const { percentage, label } = params.value as PercentageLabelColumnType;

        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            <HIDCircularProgress thickness={5} value={percentage} />
            <Typography noWrap variant="subtitle1">
              {label}
            </Typography>
          </Stack>
        );
      },
    },
    !isDownSm && ({
      field: 'count',
      headerName: t('common:amount'),
      flex: 0.3,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const category = params?.row as GroupedRecurringExpensesCategory;
        return (
          <HIDTypography sx={{ color: theme.palette.grey[500] }}>
            {category.count}
          </HIDTypography>
        );
      },
    }),
    {
      field: 'totalAmount',
      headerName: intervalsLabel,
      flex: 0.3,
      type: 'string',
      sortable: true,
      align: 'right' as GridAlignment,
      headerAlign: 'right' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const category = params?.row as GroupedRecurringExpensesCategory;
        return category.totalAmount ? formatMoney(category.totalAmount, false) : '';
      },
    },
    LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const category = params?.row as GroupedRecurringExpensesCategory;
    if (category && propertyId) {
      navigate(getRecurringExpenseCategoryPath({ propertyId, categoryId: category.id }));
    }
  };

  const [openManageRecurringExpenseCandidatesDialog] = useDialog(DialogNames.MANAGE_RECURRING_EXPENSES_CANDIDATES_DIALOG);
  useEffect(() => {
    if (manageExpenses) {
      openManageRecurringExpenseCandidatesDialog();
    }
  }, [manageExpenses]);

  useShowDialogs(RecurringExpensesCategories.name, [
    {
      uniqueId: DialogNames.TUTORIAL_DIALOG + TutorialDialogTypes.RECURRING_EXPENSE,
      name: DialogNames.TUTORIAL_DIALOG,
      target: ShowDialogItemTargetType.USER,
      props: { type: TutorialDialogTypes.RECURRING_EXPENSE },
      maxCountToShow: 1,
    } as ShowDialogItem,
  ]);

  return (
    <HomeListLayout
      hideDataGridIfNoData
      ListHeaderComponent={
        <Stack spacing={3}>
          <Stack direction="row">
            <HIDTypography>
              {t('finances:learn_more_about_housing_cost')}
            </HIDTypography>
            {' '}
            <HIDLink
              label={t('common:here')}
              sx={{ flexShrink: 0, ml: 0.5 }}
              onClick={() => openTutorialDialog({ type: TutorialDialogTypes.RECURRING_EXPENSE })}
            />
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {'.'}
          </Stack>
          {
            hasExpenses || isLoadingOrFetching
              ? (
                <ExpensesComparisonChart
                  data={filtration?.chart || []}
                  groupingType={debouncedFilters.timeIntervalGroupingType}
                  isLoading={isLoadingOrFetching}
                  lastSyncedAt={lastSyncedAt}
                  mode={FinancesFilterSectionMode.Filter}
                  primaryLabel={t('finances:total_amount')}
                />
              )
              : <RecurrentExpensesEmptyStateCard />
          }
        </Stack>
      }
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesActions />
          </Card>
          <Card sx={{ padding: 2 }}>
            <FinancesQuickNavigation
              currentPage={FinancePageNames.RECURRING_EXPENSES}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <RecurringExpensesFilterSection
              bankAccounts={bankAccounts}
              budgetsList={[]}
              filters={filters}
              showComparison={false}
              yearsList={[]}
              onChange={setFilters}
            />
          </Card>
          <RecurringExpenseSuggestionsStatusBox isSidebarBanner />
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
      ]}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'totalAmount',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={isLoadingOrFetching}
      rows={categoriesList}
      sideDrawerElements={
        [
          <RecurringExpensesActions
            isSideDrawer
            key={RecurringExpensesActions.name}
          />,
          <FinancesQuickNavigation
            currentPage={FinancePageNames.RECURRING_EXPENSES}
            key={FinancesQuickNavigation.name}
          />,
          <RecurringExpensesFilterSection
            bankAccounts={bankAccounts}
            budgetsList={[]}
            filters={filters}
            key={FiltersSection.name}
            showComparison={false}
            yearsList={[]}
            onChange={setFilters}
          />,
          <RecurringExpenseSuggestionsStatusBox
            key={RecurringExpenseSuggestionsStatusBox.name}
          />,
        ]
      }
      title={t('finances:fixed_expenses')}
      onBack={handleGoBack}
      onRowClick={handleRowClick}
    />
  );
};

export default RecurringExpensesCategories;
