import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import {
  FCC,
  Icon,
} from '../../../../../../../types/common';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import FinanceIcon from '../../../components/FinanceIcon';
import { BorderRadius } from '../../../../../../../constants/borderRadius';

type FinanceOverviewWidgetProps = {
  action: string;
  Icon: Icon;
  iconBackgroundColor: string;
  iconColor: string;
  widgetBackground: string;
  title: string;
  description: string;
  onClick?: () => void;
};

const FinanceOverviewWidget: FCC<FinanceOverviewWidgetProps> = ({
  action,
  Icon,
  iconBackgroundColor,
  iconColor,
  widgetBackground,
  title,
  description,
  sx,
  onClick,
}) => {
  const theme = useTheme();
  const { isDownMd, isDownSm } = useBreakPointsSizes();

  return (
    <Stack
      alignItems="center"
      direction="column"
      gap={2.5}
      justifyContent="center"
      sx={{
        padding: 3,
        borderStyle: 'solid',
        borderWidth: isDownSm ? 0 : 1,
        borderRadius: BorderRadius.sm,
        borderColor: theme.palette.grey[300],
        height: '100%',
        minHeight: 370,
        background: `linear-gradient(${widgetBackground}, #FFFFFF)`,
        ...sx,
      }}
    >
      <FinanceIcon
        Icon={Icon}
        iconBackgroundColor={iconBackgroundColor}
        iconColor={iconColor}
      />
      <Stack alignItems="center" direction="column" gap={1}>
        <HIDTypography sx={{ textAlign: 'center' }} variant="h6">
          {title}
        </HIDTypography>
        <HIDTypography
          sx={{
            textAlign: 'center',
            paddingLeft: isDownMd ? 0 : 4,
            paddingRight: isDownMd ? 0 : 4,
          }}
        >
          {description}
        </HIDTypography>
      </Stack>
      {onClick && (
        <HIDButton color="primary" onClick={onClick}>
          {action}
        </HIDButton>
      )}
    </Stack>
  );
};

export default FinanceOverviewWidget;
