import React, {
  FC,
  useRef,
  useState,
  KeyboardEvent,
  SyntheticEvent,
  MouseEvent,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';
import {
  Close,
  AccessTime,
  Search,
} from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  InputAdornment,
  TextField,
  useTheme,
  SelectChangeEvent,
  Stack,
  AutocompleteRenderInputParams,
} from '@mui/material';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDebounce } from 'usehooks-ts';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { useRouteQueryParams } from '../../../../../../../utils/routes';
import { getSearchResultsPath } from '../navigation.search';
import useSearch from '../../../../../../../hooks/useSearch';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import EntityTypeSelect from './EntityTypeSelect';
import AutocompletePaper from './AutocompletePaper';
import AutocompleteOption from './AutocompleteOption';
import {
  useGetPropertySettingsQuery,
  useSetPropertySettingsMutation,
} from '../../../../../../Auth/api/api.settings';
import {
  useGetAutocompleteSearchResultsQuery,
} from '../api/api.search';
import useGetEntityInfo from '../../../hooks/useGetEntityInfo';
import {
  ALL_SEARCH_RESULTS_CATEGORY,
  SearchResultType,
} from '../constants.search';
import { SearchAutocompleteResultItem } from '../types.search';

const MIN_CHARS_LENGTH = 2;
const MAX_LAST_SEARCH_RESULTS = 5;
const DEBOUNCED_VALUE_DELAY = 500;

type SearchAutocompleteProps = {
  propertyId: string;
};

const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  propertyId,
}) => {
  const popperRef = useRef<HTMLDivElement | null>(null);
  const textInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const { t, i18n: { language } } = useTranslation(['common', 'search']);

  const { isDownMd } = useBreakPointsSizes();

  const theme = useTheme();
  const getEntityInfo = useGetEntityInfo();

  const { entityType, query } = useRouteQueryParams<{ entityType?: EntityType, query?: string }>();

  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<SearchAutocompleteResultItem | string | null>(null);

  const {
    isFocused: isOpen,
    entityType: searchEntityType,
    setSearchState,
    hideSearch,
  } = useSearch();

  const {
    currentData,
    isLoading: isLoadingPropertySettings,
  } = useGetPropertySettingsQuery(
    propertyId ? { propertyId } : skipToken,
  );

  const [setPropertySettings] = useSetPropertySettingsMutation();

  const debouncedInputValue = useDebounce(inputValue, DEBOUNCED_VALUE_DELAY);

  const {
    data: autocompleteOptions,
    isFetching: isFetchingAutocompleteSearchResults,
  } = useGetAutocompleteSearchResultsQuery(
    debouncedInputValue?.length >= MIN_CHARS_LENGTH
      ? {
        propertyId,
        lang: language,
        query: debouncedInputValue,
        entityType: ALL_SEARCH_RESULTS_CATEGORY !== searchEntityType as string ? searchEntityType : undefined,
      }
      : skipToken,
  );

  useEffect(() => {
    if (autocompleteOptions && currentData && debouncedInputValue) {
      const propSettings = currentData?.propertySettings || {};
      const prevSearches = propSettings.lastSearchRequests || [];
      const lastSearchRequests = prevSearches.length
        ? prevSearches.includes(debouncedInputValue)
          ? prevSearches : [debouncedInputValue, ...prevSearches].slice(0, MAX_LAST_SEARCH_RESULTS)
        : [debouncedInputValue];

      setPropertySettings({
        propertyId,
        settings: {
          propertySettings: {
            ...propSettings,
            lastSearchRequests,
          },
        },
      });
    }
  }, [debouncedInputValue]);

  const isVisibleEntityTypeSelect = Boolean(entityType || searchEntityType);

  useEffect(() => {
    const q = query || '';
    if (q !== inputValue) {
      setInputValue(q);
    }
  }, [query]);

  useEffect(() => {
    if (textInputRef.current && isOpen) {
      textInputRef.current.focus();
    }
  }, [isOpen]);

  const handleOpen = () => setSearchState({ isFocusedSearch: true });

  const handleClose = () => setSearchState({ isFocusedSearch: false });

  const handleInputChange = (_: SyntheticEvent, newInputValue: string) => setInputValue(newInputValue);

  const handleSelectEntityType = (event: SelectChangeEvent) => setSearchState({ entityType: event.target.value as EntityType });

  const handleHideSelect = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    hideSearch();
  };

  const handleSearch = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClose();
    navigate(getSearchResultsPath({ propertyId, entityType: searchEntityType, query: inputValue }));
  };

  const handleClearInput = () => {
    if (inputValue) {
      setInputValue('');
    }
    if (selectedOption) {
      setSelectedOption(null);
    }
  };

  const handleClearLastSearchRequests = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPropertySettings({
      propertyId,
      settings: {
        propertySettings: {
          ...currentData?.propertySettings,
          lastSearchRequests: [],
        },
      },
    });
    handleClose();
  };

  const handleSelect = (_: SyntheticEvent, newValue: SearchAutocompleteResultItem | string | null) => {
    if (!newValue || typeof newValue === 'string') {
      setSelectedOption(newValue);
    } else if (newValue.type === SearchResultType.HIT) {
      const entityInfo = getEntityInfo(newValue.entityType);

      handleClose();

      if (entityInfo?.getViewLink) {
        navigate(entityInfo.getViewLink({ propertyId, id: newValue.id }));
      }
    } else if (newValue.type === SearchResultType.FILTER) {
      if (!R.isEmpty(newValue.typeFilters)) {
        navigate(getSearchResultsPath({ propertyId }), { state: newValue.typeFilters });
      } else {
        navigate(getSearchResultsPath({ propertyId, query: inputValue }));
      }

      handleClose();
    }
  };

  const getOptionName = (option: SearchAutocompleteResultItem | string) => typeof option === 'string' ? option : option?.name || '';

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClose();
      navigate(getSearchResultsPath({ propertyId, entityType: searchEntityType, query: inputValue }));
    }
  };

  const inputBorderRadius = theme.spacing(5);

  const isLoadingData = isLoadingPropertySettings || isFetchingAutocompleteSearchResults;

  const lastSearches = currentData?.propertySettings?.lastSearchRequests;
  const hasLastSearches = Boolean(lastSearches?.length && !inputValue);

  const options = ((hasLastSearches ? lastSearches : autocompleteOptions) || []).filter(Boolean);

  const isAutocompleteBodyOpened = Boolean(isOpen && (isLoadingData || options.length || inputValue || selectedOption));

  const getCategoryFromOption = (option: SearchAutocompleteResultItem | string) => {
    if (!hasLastSearches && typeof option !== 'string' && option.type === SearchResultType.HIT) {
      return getEntityInfo(option.entityType)?.name;
    }

    return undefined;
  };

  const autocompleteInputRender = (params: AutocompleteRenderInputParams) => (
    <TextField
      autoFocus
      InputProps={{
        ...params.InputProps,
        startAdornment: !isDownMd && (
          <InputAdornment position="start">
            <Search sx={{ color: theme.palette.common.black }} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            <InputAdornment position="end">
              {selectedOption || inputValue
                ? (
                  <HIDIconButton
                    Icon={Close}
                    size={params.size}
                    onClick={handleClearInput}
                  />
                ) : (
                  <HIDButton
                    childrenContainerSx={{
                      color: theme.palette.common.black,
                    }}
                    color="blank"
                    size={params.size}
                    sx={{
                      marginRight: '-4px',
                      borderRadius: inputBorderRadius,
                      color: theme.palette.common.black,
                      backgroundColor: theme.palette.grey[50],
                      '&:hover': {
                        backgroundColor: theme.palette.grey[100],
                      },
                    }}
                    variant="text"
                    onClick={handleHideSelect}
                  >
                    {t('common:close')}
                  </HIDButton>
                )}
            </InputAdornment>
            {inputValue && (
              <InputAdornment position="end">
                {
                  isDownMd
                    ? (
                      <HIDIconButton
                        Icon={Search}
                        color="secondary"
                        size={params.size}
                        onClick={handleSearch}
                      />
                    ) : (
                      <HIDButton
                        color="primary"
                        size={params.size}
                        sx={{ marginRight: '-4px' }}
                        variant="text"
                        onClick={handleSearch}
                      >
                        {t('common:search')}
                      </HIDButton>
                    )
                }
              </InputAdornment>
            )}
          </>
        ),
        sx: {
          backgroundColor: theme.palette.common.white,
          borderTopRightRadius: inputBorderRadius,
          borderBottomRightRadius: inputBorderRadius,
          borderTopLeftRadius: isVisibleEntityTypeSelect ? 0 : inputBorderRadius,
          borderBottomLeftRadius: isVisibleEntityTypeSelect ? 0 : inputBorderRadius,
        },
      }}
      fullWidth={params.fullWidth}
      inputProps={{
        ...params.inputProps,
        value: inputValue,
      }}
      inputRef={textInputRef}
      placeholder={t('common:search')}
      size={params.size}
      type="search"
    />
  );

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        ref={popperRef}
        sx={{
          borderRadius: inputBorderRadius,
          width: '100%',
          maxWidth: 520,
          zIndex: 3,
        }}
      >
        {isVisibleEntityTypeSelect && (
          <EntityTypeSelect
            value={searchEntityType}
            onSelect={handleSelectEntityType}
          />
        )}
        <Autocomplete<SearchAutocompleteResultItem | string>
          autoComplete
          // @ts-ignore
          disableClearable
          fullWidth
          PaperComponent={(props) => (
            <AutocompletePaper
              className={props.className}
              hasLastSearches={hasLastSearches}
              isLoading={isLoadingData}
              onClear={handleClearLastSearchRequests}
            >
              {props.children}
            </AutocompletePaper>
          )}
          clearOnBlur={false}
          componentsProps={{
            popper: {
              anchorEl: popperRef.current,
              style: popperRef.current
                ? {
                  width: popperRef.current.clientWidth,
                }
                : {},
              sx: {
                borderRadius: theme.spacing(2),
              },
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            },
          }}
          forcePopupIcon={false}
          getOptionLabel={getOptionName}
          inputValue={inputValue}
          isOptionEqualToValue={() => false}
          loading={isLoadingData}
          noOptionsText={t('search:no_search_results')}
          open={isAutocompleteBodyOpened}
          options={options}
          popupIcon={null}
          renderInput={autocompleteInputRender}
          renderOption={(props, option, state) => (
            <AutocompleteOption
              Icon={hasLastSearches ? AccessTime : Search}
              attributes={props}
              category={getCategoryFromOption(option)}
              label={getOptionName(option)}
              state={state}
            />
          )}
          size="small"
          value={selectedOption}
          onBlur={handleClose}
          onChange={handleSelect}
          onFocus={handleOpen}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onOpen={handleOpen}
        />
      </Stack>
      <Backdrop
        open={isOpen}
        sx={{
          zIndex: 2,
          backgroundColor: theme.palette.shadow[200],
          backdropFilter: 'blur(3px)',
        }}
        onClick={handleClose}
      />
    </>
  );
};

export default SearchAutocomplete;
