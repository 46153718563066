import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Loan: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m8.18277,15.01847l0,-4.26138l5.68189,0l0,1.24293m-10.8313,7.99009l0,-11.54122l7.99016,-6.21453l7.99009,6.21453l0,1.73119m-12.25137,11.58557l4.6165,-4.61648l2.84094,2.84094l6.03692,-6.03698m-2.13082,-0.71019l2.84088,0l0,2.84094"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </HIDSvgIcon>
  );
};

export default Loan;
