import {
  DeductionItemType,
  DeductionWithReceipts,
  DeductionWithDocuments,
} from '@house-id/houseid-types/dist/finances/finances';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ReceiptInitialData } from '@house-id/houseid-types/dist/content/receipt';

import { QueryHook } from '../../../../../../../types/common';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  CONTRACT_CATEGORY_MORTGAGE_DEED_TYPE,
  CONTRACT_CATEGORY,
  CERTIFICATE_CATEGORY_TITLE_DEED_TYPE,
  CERTIFICATE_CATEGORY,
} from '../../../../Content/modules/Document/constants.document';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../api/api.propertyFinance';
import { getCreateDocumentPath } from '../../../../Content/modules/Document/navigation.document';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { getCreateReceiptPath } from '../../../../Content/modules/Receipt/navigation.receipt';

export type DeductionItem = {
  id: DeductionItemType;
  label: string;
  title: string;
  details: DeductionWithReceipts | DeductionWithDocuments | undefined;
  value: number;
  onAdd: () => void;
};

type UseGetDeductionItems = QueryHook<Array<DeductionItem> | undefined>;

const getDocumentsAmount = (deduction: DeductionWithDocuments | undefined) => deduction
  ? (deduction.amount || 0) + (deduction.documentsAmount || 0)
  : 0;

const getReceiptsAmount = (deduction: DeductionWithReceipts | undefined) => deduction
  ? (deduction.amount || 0) + (deduction.includeReceipts ? deduction.receiptAmount || 0 : 0)
  : 0;

const useGetDeductionItems: UseGetDeductionItems = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(['declarations']);
  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: { deductions } = {},
    ...rest
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const handleNavigateToDoCreateDocument = (categoryId: string, classificationType?: string) =>
    navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, categoryId, typeId: classificationType }));

  const handleNavigateToCreateReceipt = (initialData?: ReceiptInitialData) =>
    navigate(getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }), {
      state: {
        initialData,
      },
    });

  const deductionItems: Array<DeductionItem> | undefined = deductions
    ? [
      {
        id: DeductionItemType.COST_OF_MORTGAGE_DEED,
        label: t('declarations:declarations_declaration_your_deductions_cost_mortgage_deed'),
        details: deductions[DeductionItemType.COST_OF_MORTGAGE_DEED],
        value: getDocumentsAmount(deductions[DeductionItemType.COST_OF_MORTGAGE_DEED]),
        title: t('declarations:declarations_declaration_your_deductions_cost_mortgage_deed_title'),
        onAdd: () => handleNavigateToDoCreateDocument(CONTRACT_CATEGORY, CONTRACT_CATEGORY_MORTGAGE_DEED_TYPE),
      },
      {
        id: DeductionItemType.COST_OF_LEGAL_REGISTRATION,
        label: t('declarations:declarations_declaration_your_deductions_cost_title_deeds'),
        details: deductions[DeductionItemType.COST_OF_LEGAL_REGISTRATION],
        value: getDocumentsAmount(deductions[DeductionItemType.COST_OF_LEGAL_REGISTRATION]),
        title: t('declarations:declarations_declaration_your_deductions_cost_title_deeds_title'),
        onAdd: () => handleNavigateToDoCreateDocument(CERTIFICATE_CATEGORY, CERTIFICATE_CATEGORY_TITLE_DEED_TYPE),
      },
      {
        id: DeductionItemType.SELLING_EXPENSES,
        label: t('declarations:declarations_declaration_your_deductions_selling_expenses'),
        details: deductions[DeductionItemType.SELLING_EXPENSES],
        value: getReceiptsAmount(deductions[DeductionItemType.SELLING_EXPENSES]),
        title: t('declarations:declarations_declaration_your_deductions_selling_expenses_title'),
        onAdd: () => handleNavigateToCreateReceipt(),
      },
      {
        id: DeductionItemType.IMPROVEMENTS_EXPENDITURES,
        label: t('declarations:declarations_declaration_your_deductions_basic_improvements'),
        details: deductions[DeductionItemType.IMPROVEMENTS_EXPENDITURES],
        value: getReceiptsAmount(deductions[DeductionItemType.IMPROVEMENTS_EXPENDITURES]),
        title: t('declarations:declarations_declaration_your_deductions_basic_improvements_title'),
        onAdd: () => handleNavigateToCreateReceipt(),
      },
      {
        id: DeductionItemType.OTHER_DEDUCTIONS,
        label: t('declarations:declarations_declaration_your_deductions_repair_maintenance'),
        details: deductions[DeductionItemType.OTHER_DEDUCTIONS],
        value: getReceiptsAmount(deductions[DeductionItemType.OTHER_DEDUCTIONS]),
        title: t('declarations:declarations_declaration_your_deductions_repair_maintenance_title'),
        onAdd: () => handleNavigateToCreateReceipt(),
      },
    ] : undefined;

  return {
    ...rest,
    data: deductionItems,
  };
};

export default useGetDeductionItems;
