import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';

import InviteImage from '../../../../../assets/images/invite.png';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import {
  useGetPropertySettingsQuery,
  useSetPropertySettingsMutation,
} from '../../../../Auth/api/api.settings';
import { getActiveAuth } from '../../../../../external-services/firebase';

const InviteWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home', 'property']);

  const {
    isDownLg,
    isDownMd,
    isDownSm,
  } = useBreakPointsSizes();

  const imageHeight = R.cond([
    [() => isDownSm, R.always(0)],
    [() => isDownMd, R.always(140)],
    [() => isDownLg, R.always(160)],
    [R.T, R.always(188.5)],
  ])();

  const auth = getActiveAuth();

  const { data: propertyId } = useGetCurrentPropertyId();
  const [openInviteUserDialog] = useDialog(DialogNames.INVITE_USER_DIALOG);

  const { currentData: propertySettingsData } = useGetPropertySettingsQuery(
    auth.currentUser?.uid && propertyId ? { propertyId } : skipToken,
  );

  const [setPropertySettings] = useSetPropertySettingsMutation();

  const handleReadMore = () => {
    if (propertyId) {
      openInviteUserDialog({ propertyId });
    }
  };

  const handleClose = () => {
    if (propertyId) {
      setPropertySettings({
        propertyId,
        settings: {
          propertySettings: {
            ...propertySettingsData?.propertySettings,
            hideInviteWidget: true,
          },
        },
      });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Stack
        direction="row"
        sx={{
          backgroundColor: theme.palette.primary.dark,
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            alignSelf: 'stretch',
            padding: theme.spacing(2.5),
          }}
        >
          <Stack spacing={1.5} sx={{ marginBottom: 2 }}>
            <Typography sx={{ color: theme.palette.common.white }} variant="h4">
              {t('home:home_are_there_more_people_in_your_home')}
            </Typography>
            <Typography sx={{ color: theme.palette.common.white }} variant="body1">
              {t('home:home_taking_care_of_your_home_alone')}
            </Typography>
          </Stack>
          <HIDButton
            color="blank"
            style={{ marginTop: 'auto' }}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleReadMore}
          >
            {t('property:invite_user')}
          </HIDButton>
        </Stack>
        {
          !isDownSm && (
            <img
              src={InviteImage}
              style={{
                alignSelf: 'flex-end',
                height: imageHeight,
                marginRight: theme.spacing(-5),
                marginLeft: 'auto',
              }}
            />
          )
        }
        <HIDIconButton
          Icon={() => <Close sx={{ color: theme.palette.common.white }} />}
          color="blank"
          sx={{ alignSelf: 'flex-start' }}
          onClick={handleClose}
        />
      </Stack>
    </Card>
  );
};

export default InviteWidget;
