import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { formatMoney } from '../../../../../../../../../utils/string';
import HIDTypography from '../../../../../../../../../components/HIDTypography';

export type DeclarationCalculationsTableItem = {
  id: string;
  label: string;
  value: number;
  prefix?: string;
  suffix?: string;
  isImportant?: boolean;
  withBackground?: boolean;
  Component?: React.ReactNode;
};

type DeclarationCalculationsTableProps = {
  items: Array<DeclarationCalculationsTableItem>;
  loading?: boolean;
};

const DeclarationCalculationsTable: React.FC<DeclarationCalculationsTableProps> = ({
  items,
  loading,
}) => {
  const theme = useTheme();

  return (
    <Stack gap={0.5}>
      {items.filter(Boolean).map((item, index) => (
        <Stack
          display="flex"
          flexDirection="row"
          gap={0.5}
          justifyContent="space-between"
          key={index}
        >
          <HIDTypography
            sx={{ flex: 1 }}
            variant="body1"
          >
            {item?.label}
          </HIDTypography>
          <Stack
            flexDirection="row"
            justifyContent={item.prefix ? 'space-between' : 'flex-end'}
            sx={{
              backgroundColor: !loading && item.withBackground
                ? item.value > 0 ? theme.palette.primary.lighter : theme.palette.error.lighter
                : undefined,
              minWidth: 160,
            }}
          >
            {item.prefix !== undefined && (
              <HIDTypography isLoading={loading} skeletonWidth={0} variant={item.isImportant ? 'subtitle1' : 'body1'}>
                {item.prefix}
              </HIDTypography>
            )}
            {item.value !== undefined && (
              <HIDTypography
                isLoading={loading}
                skeletonWidth={100}
                variant={item.isImportant ? 'subtitle1' : 'body1'}
              >
                {item.suffix ? `${item.value}${item.suffix}` : formatMoney(item.value || 0)}
              </HIDTypography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
export default DeclarationCalculationsTable;
