import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import FinanceOverviewWidget from './FinanceOverviewWidget';
import BarChart from '../../../../../../../components/icons/BarChart';
import { FCC } from '../../../../../../../types/common';
import { getValuationOverviewPath } from '../../../modules/Valuation/navigation.valuation';
import { useGetPropertyFinanceInformationQuery } from '../../../api/api.propertyFinance';
import HIDSection from '../../../../../../../components/HIDSection';
import LatestValuationInfo from '../../../modules/Valuation/components/LatestValuationInfo';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';

const ValuationOverviewWidget: FCC = ({
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'forms_common', 'finances']);
  const theme = useTheme();

  const { isDownLg, isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeInformation,
    isLoading,
    isFetching,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const latestMovestaValuation = financeInformation?.latestMovestaValuation;

  const handleNavigateValuation = () => navigate(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }));

  const valuationDate = financeInformation?.latestValuation?.date;

  const Container = isDownSm ? Stack : HIDSection;

  return latestMovestaValuation?.expectation || isLoading || isFetching
    ? (
      <Container sx={{ gap: 2, ...sx, justifyContent: 'space-between' }}>
        <LatestValuationInfo
          chartMinHeight={isDownLg ? 173 : 129}
          showIcon={!isDownLg}
        />
        <Stack
          flexDirection={isDownLg ? 'row' : 'column'}
          gap={isDownLg ? 0 : 1}
          justifyContent={isDownLg ? 'space-between' : 'flex-end'}
        >
          <HIDTypography
            color={theme.palette.grey[500]}
            sx={{ alignSelf: 'flex-end' }}
            variant="body1"
          >
            {t('forms_common:last_updated_date', { date: formatDate(new Date(valuationDate as string), DateTimeFormats.DATE_ONLY) })}
          </HIDTypography>
          <HIDButton
            sx={{ alignSelf: 'flex-end', minWidth: 120 }}
            onClick={handleNavigateValuation}
          >
            {t('common:view')}
          </HIDButton>
        </Stack>
      </Container>
    )
    : (
      <FinanceOverviewWidget
        Icon={BarChart}
        action={t('finances:proceed_to_valuation')}
        description={t('finances:get_a_value_indication_and_track_your_homes_approximate_value')}
        iconBackgroundColor={theme.palette.primary.lighter}
        iconColor={theme.palette.primary.dark}
        sx={sx}
        title={t('finances:get_a_digital_value_indication')}
        widgetBackground={theme.palette.primary.lightest}
        onClick={handleNavigateValuation}
      />
    );
};

export default ValuationOverviewWidget;
