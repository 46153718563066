import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  NorthEastRounded,
  SouthEastRounded,
} from '@mui/icons-material';

import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetLatestIndicationValuationQuery } from '../../../api/api.valuation';
import { IndicativeValuation } from '../../../types.valuation';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../../../utils/string';
import {
  formatPercentage,
  truncatePercentage,
} from '../../../../../../../../../utils/number';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';
import HIDSection from '../../../../../../../../../components/HIDSection';
import IndicationValuationHistoryChart from './IndicationValuationHistoryChart';

export const getIndicativeValuationMin = (indicativeValuation: IndicativeValuation | undefined) =>
  indicativeValuation?.valuation?.range?.low;

export const getIndicativeValuationMax = (indicativeValuation: IndicativeValuation | undefined) =>
  indicativeValuation?.valuation?.range?.high;

const LatestIndicationValuationSection: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'finances']);

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: indicationValuation,
    isLoading,
  } = useGetLatestIndicationValuationQuery(propertyId ? { propertyId } : skipToken);

  const totalMovestaValuation = indicationValuation?.valuation?.expectation || 0;

  const minValuation = getIndicativeValuationMin(indicationValuation);
  const maxValuation = getIndicativeValuationMax(indicationValuation);

  const difference = indicationValuation?.purchasePriceChange?.amount;
  const differenceInPercent = indicationValuation?.purchasePriceChange?.fractal;

  if (!isLoading && !indicationValuation?.purchasePriceChange) {
    return null;
  }

  return (
    <HIDSection sx={{ gap: 3 }}>
      <Stack direction="column" gap={0.5}>
        <HIDTypography variant="subtitle1">
          {t('finances:latest_valuation')}
        </HIDTypography>
        <HIDTypography isLoading={isLoading} skeletonWidth={140} variant="h5">
          {formatMoney(totalMovestaValuation, false)}
        </HIDTypography>
        {(isLoading || differenceInPercent !== undefined) && (
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={1}
          >
            <HIDTypography
              color={differenceInPercent && differenceInPercent >= 0 ? theme.palette.primary.main : theme.palette.error.main}
              isLoading={isLoading}
              skeletonWidth={40}
              variant="subtitle1"
            >
              {formatPercentage(truncatePercentage(differenceInPercent || 0), { wholeNumber: true, withPrefixSign: true })}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={95}
              variant="subtitle1"
            >
              {`${formatMoney(difference, false)},`}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={75}
            >
              {t('finances:latest_valuation_finance_info_text')}
            </HIDTypography>
          </Stack>
        )}
        <Stack flexDirection={isDownSm ? 'column' : 'row'} gap={isDownSm ? 0.5 : 2}>
          <Stack flexDirection="row" gap={1}>
            <NorthEastRounded height={20} sx={{ color: theme.palette.primary.main }} width={20} />
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={50}
            >
              {`${t('home:home_highest')}:`}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={100}
              variant="subtitle1"
            >
              {formatMoney(maxValuation, false)}
            </HIDTypography>
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <SouthEastRounded height={20} sx={{ color: theme.palette.error.main }} width={20} />
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={50}
            >
              {`${t('home:home_lowest')}:`}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={100}
              variant="subtitle1"
            >
              {formatMoney(minValuation, false)}
            </HIDTypography>
          </Stack>
        </Stack>
      </Stack>
      <IndicationValuationHistoryChart />
    </HIDSection>
  );
};

export default LatestIndicationValuationSection;
