import qs from 'query-string';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import {
  Task,
  TaskTemplate,
} from '../types.task';
import { propertyApi } from '../../../../../api/api.property';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

export const taskApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    createTask: builder.mutation<Task, { propertyId: string, task: Partial<Task> }>({
      query: ({ propertyId, task }) => ({
        url: `/properties/${propertyId}/tasks`,
        method: 'POST',
        body: task,
      }),
      // invalidatesTags: () => [HIDApiTags.TASK, HIDApiTags.TASK_GROUP, HIDApiTags.TASK_SUMMARY],
    }),
    getTasks: builder.query<Array<Task>, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/tasks`,
      providesTags: (result) => provideArrayTags(HIDApiTags.TASK, result),
      transformResponse: (result: { tasks: Array<Task> }) => result?.tasks || [],
    }),
    getTaskTemplates: builder.query<Array<TaskTemplate>, PropertyId & { query?: string }>({
      query: ({ propertyId, query }) => ({
        url: `/properties/${propertyId}/tasks/templates?${qs.stringify({ query })}`,
        method: 'GET',
      }),
      transformResponse: (response: { templates: Array<TaskTemplate> }) => response.templates,
      providesTags: (result) => result ? provideArrayTags(HIDApiTags.TASK_TEMPLATE, result) : [],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetTaskTemplatesQuery,
  useCreateTaskMutation,
} = taskApi;
