import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetLatestIndicationValuationQuery } from '../../../api/api.valuation';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../../../utils/string';
import {
  formatPercentage,
  getNumberSignPrefix,
  truncatePercentage,
} from '../../../../../../../../../utils/number';
import DocMoney from '../../../../../../../../../components/icons/DocMoney';
import TrendChart from '../../../../../../../../../components/icons/TrendChart';
import Discount from '../../../../../../../../../components/icons/Discount';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';

const ValuationHistoryKeyFigures: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: indicationValuation,
    isLoading,
  } = useGetLatestIndicationValuationQuery(propertyId ? { propertyId } : skipToken);

  const { isDownLg, isDownMd } = useBreakPointsSizes();

  const valueDifferenceFromPurchase = indicationValuation?.purchasePriceChange?.amount || 0;
  const valueDifferenceFromPurchasePerDay = indicationValuation?.purchasePriceChange?.amountPerDay || 0;
  const valueDifferenceFromPurchaseInPercent = indicationValuation?.purchasePriceChange?.fractal || 0;

  if (!isLoading && !indicationValuation?.purchasePriceChange) {
    return null;
  }

  return (
    <Stack gap={3}>
      <HIDTypography variant="h5">
        {t('finances:your_key_figures')}
      </HIDTypography>
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
      >
        <Stack
          gap={0.5}
          sx={{
            borderRadius: BorderRadius.md,
            padding: 2,
            backgroundColor: theme.palette.grey[100],
            minWidth: 190,
            flex: 1,
          }}
        >
          <HIDTypography>{t('finances:development_since_purchase')}</HIDTypography>
          <DocMoney size={20} />
          <HIDTypography isLoading={isLoading} variant="h6">
            {getNumberSignPrefix(valueDifferenceFromPurchase)}
            {' '}
            {formatMoney(Math.abs(valueDifferenceFromPurchase), false)}
          </HIDTypography>
        </Stack>
        {Boolean(isLoading || valueDifferenceFromPurchasePerDay) && (
          <Stack
            gap={0.5}
            sx={{
              borderRadius: BorderRadius.md,
              padding: 2,
              backgroundColor: theme.palette.grey[100],
              minWidth: 190,
              flex: 1,
            }}
          >
            <HIDTypography>{t('finances:development_average_day')}</HIDTypography>
            <TrendChart size={20} />
            <HIDTypography isLoading={isLoading} variant="h6">
              {getNumberSignPrefix(valueDifferenceFromPurchasePerDay)}
              {' '}
              {formatMoney(Math.abs(valueDifferenceFromPurchasePerDay), false)}
            </HIDTypography>
          </Stack>
        )}
        <Stack
          gap={0.5}
          sx={{
            borderRadius: BorderRadius.md,
            padding: 2,
            backgroundColor: valueDifferenceFromPurchaseInPercent >= 0
              ? theme.palette.primary.lightest
              : theme.palette.error.lightest,
            minWidth: 190,
            flex: isDownLg && !isDownMd ? 2 : 1,
          }}
        >
          <HIDTypography>{t('finances:development_in_percentage')}</HIDTypography>
          <Discount iconColor={theme.palette.primary.main} size={20} />
          <HIDTypography isLoading={isLoading} variant="h6">
            {formatPercentage(
              truncatePercentage(valueDifferenceFromPurchaseInPercent),
              { withPrefixSign: true, wholeNumber: true },
            )}
          </HIDTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ValuationHistoryKeyFigures;
